import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import UpdateFormElements from "./SkillsUpdateFormElements"
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


import WebForm from '../../html/WebForm';

const SkillsUpdateModal = ({ isVisible, setIsVisible, Id, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [skillData, setSkillData] = useState(null);
  const APIEndPoint = `${URL}/skills/`;
  const {accessToken} = useAuthContext();

  const preFormSubmission = (data) => {
    console.log("preFormSubmission");
};

const postFormSubmission = (response) => {
    console.log("postFormSubmission");
    onFresh();
}

const Form = {
    'title' : 'Update Skill',
    'restAPI' : APIEndPoint,
    'apiMethod' : 'PUT',
    'elements' : UpdateFormElements,
    'preHandler' : preFormSubmission,
    'postHandler' : postFormSubmission
};

  useEffect(() => {
    const fetchData = async () => {
      try {
          if (Id && isVisible) {
              const data = await DataService.getById(APIEndPoint + Id, accessToken);
              console.log("fetchData Response for id " + Id);
              setSkillData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [Id,isVisible]);


  return (
      skillData&& isVisible ? (
        <WebForm
            id={Id}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            form={Form}
            formData={skillData}
            setFormData={setSkillData}
        /> 
      ) :  null
  );
};

export default withAuthenticationRequired(SkillsUpdateModal, {
  onRedirecting: () => <Loading />,
});