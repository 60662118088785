import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import { AppConfigContext } from '../../config/AppConfig';
import "./DemandDeleteModal.css";

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const DemandDeleteModal = ({ isVisible, setIsVisible, Id, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;


  const [demandData, setDemandData] = useState(null);
  const APIEndPoint = `${URL}/demand/`;
  const {accessToken} = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
          if (Id && isVisible) {
              let url = `${APIEndPoint}${Id}`;
              console.log("fetchData Response id " + Id);
              console.log("fetchData Response url " + url);
              const data = await DataService.getById(url, accessToken);
              console.log(data);
              setDemandData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [Id, isVisible]);

  const handleDelete = async () => {
    console.log("Proceeding to delete the Demand " + Id);
    try {
      await DataService.deleteById(`${APIEndPoint}${Id}`,accessToken);
      console.log("Deletion completed for " + Id);
      setIsVisible(false);
      console.log("Calling onFresh to refresh the table");
      onFresh();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    console.log("Demand Delete cancelled");
    setIsVisible(false);
  };

  return (
      demandData && isVisible ? (
        <div className="delete-popup-container">
        <div>
          <h2>Delete Demand</h2>
          <p>Are you sure you want to delete demand {demandData.title}?</p>
          <button onClick={handleDelete}>Delete</button>
          &nbsp;&nbsp;
          <button onClick={handleClose}>Cancel</button>
        </div>
        </div>
      ) :  null
  );
};

export default withAuthenticationRequired(DemandDeleteModal, {
  onRedirecting: () => <Loading />,
});