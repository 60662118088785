import WebTable from "../../html/WebTableDrill";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';   

import DeleteModal from "./PortfolioDeleteModal";
import UpdateModal from "./PortfolioUpdateModal";

const PortfolioList = ({updateHandler, data}) => {

    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedPfId, setSelectedPfId] = useState(null);

    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const handleUpdate = (rowData) => {
        console.log("Handle Update : " + rowData)

        setSelectedClientId(rowData.clientId);
        setSelectedPfId(rowData.pfId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle Delete : " + rowData)
        setSelectedClientId(rowData.clientId);
        setSelectedPfId(rowData.pfId);
        setIsDeleteActionPopupVisible(true);
    };


    const columns = {
        "clientName"  : "Client Name",
        "portfolioName" : "Portfolio Name",
        "remarks" : "Remarks"
    };

    const rowActions = [
       {
            'action' : 'Edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'pfId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'pfId',
            'handler' : handleDelete,
       },
    ];

    return (
        <div id="portfolioList">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                onSort={true}
                onSearch={true}
                onPageNavigation={true}
            />
            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                clientId={selectedClientId}
                pfId={selectedPfId}
                onFresh={updateHandler}
            />

            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                clientId={selectedClientId}
                pfId={selectedPfId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default PortfolioList;