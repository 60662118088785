import React, { useState, useContext, useEffect } from 'react';
import WebForm from '../../html/WebForm';
import WebFormHeader from "../../html/WebFormHeader";
import SearchFormElements from './SearchFormElements';
import DataService from '../../DataService';
import { cityList, stateList } from "./SearchFormData";
import { AppConfigContext } from '../../config/AppConfig';


const SearchForm = () => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedState, setSelectedState] = useState(null);

  const APIEndPoint = `${URL}/profilesearch/`;

 
  const preFormSubmission = (data) => {
        console.log("preFormSubmission");
        console.log(data);
  };

  const postFormSubmission = (response) => {
        console.log("postFormSubmission");
        console.log(response);
  }

  const fetchData = async (keyword) => {
    try {
      console.log("SearchForm fetchData for Keyword : " + keyword);
      let url = `${APIEndPoint}${keyword}`;
      console.log("Search URL is " + url);
      const response = await DataService.getAll(url);
      if (!response) {
        throw new Error(`API request failed`);
      }

      //console.log("fetchData Response for query " + keyword);
      let dataList = response.data.map(Item => {
        return {
          'value': Item.profileId,
          'label' : Item.firstName + " " + Item.lastName,
          'left' : Item.firstName + " " + Item.lastName,
          'right' : Item.emailId,
        }
      });
      return dataList;
    } catch (error) {
      console.log("SearchForm Fetch Data Error");
      console.error(error);
      return null;
    }
  }

  const setDependentOn = (value) => {
    console.log("Selected State  " + value);
    setSelectedState(value);
    SearchFormElements[3][1] = {
      ...SearchFormElements[3][1],
      options : cityList[value],
    };
  }

  const searchHandler = async (keyword) => {
        if(keyword.length > 1) {
          //console.log("Searching for keyword " + keyword);
          return fetchData(keyword);
        }else {
          //console.log("Not Searching for keyword " + keyword);
        }
  }

  SearchFormElements[1][0] = {
      ...SearchFormElements[1][0],
      'searchHandler': searchHandler,
  };

  SearchFormElements[3][0].callback = setDependentOn;
  SearchFormElements[3][1].depends = selectedState;

  const Form = {
        'title' : 'New User',
        'restAPI' : 'http://localhost:8081/api/v1/test',
        'apiMethod' : 'POST',
        'elements' : SearchFormElements,
        'preHandler' : preFormSubmission,
        'postHandler' : postFormSubmission
  };

  return (
    <div className="container"> {/* Changed container class */}
      <WebFormHeader title="Users" label="+ New User" onClick={() => setIsFormVisible(true)} />
      <br />
      <WebForm
        isVisible={isFormVisible}
        setIsVisible={setIsFormVisible}
        form={Form}
      /> 
    </div>
  );
};

export default SearchForm;