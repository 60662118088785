import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import AccountNewModal from './AccountNewModal'
import AccountDetails from './AccountDetails';
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from '../../../pages/components/Loading';
import AppError from '../../../pages/AppError';

const AccountPage = () => {

    const AppConfig = useContext(AppConfigContext);

    const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
    const APIEndPoint = `${URL}/account/`;
    const [data, setData] = useState([]);
    const { accessToken } = useAuthContext();
    const [state, setState] = useState({
        accountForm : false,
        accountDetails: false,
        loading: true,
        error: null,
      });

    const getAccountDetails = async () => {
        // Update data based on new submission (e.g., fetch new data or add new client)
        try {
            const data = await DataService.getById(APIEndPoint, accessToken);

            if (!data) {
                //Account not yet setup
                //Show the Account Subscription / Setup Form
                setData(null);
                setState({
                    ...state,
                    accountForm: true,
                    accountDetails: false,
                    loading: false,
                    error: null,
                });
                return;
            }

            console.log("Account Subscription Details ");
            console.log(data);
            setData(data);
            setState({
                ...state,
                accountForm: false,
                accountDetails: true,
                loading: false,
                error: null,
            });
        } catch (error) {
            console.log("Account Data fetch error : ", error.status);
            if(error.status === 401 || error.status === 404) {
                console.log("Account not yet setup.")
                setData(null);
                setState({
                    ...state,
                    accountForm: true,
                    accountDetails: false,
                    loading: false,
                    error: null
                });

            } else {
                const AppErrorMessage = {
                    "status": error.status,
                    "error": error.response.data.error,
                    "message": error.response.data.message
                };
                console.log(AppErrorMessage);
                setData(null);
                setState({
                    ...state,
                    accountForm: false,
                    accountDetails: false,
                    loading: false,
                    error: AppErrorMessage
                });
            }
        }
    };

    useEffect(() => {
        getAccountDetails();
    }, []);

    return (
        <>
            {state.loading && (
                <div> <Loading /> </div>
            )}
            {state.error && (
                <div>
                    <AppError
                        headerTitle={`App Error - ${state.error.status}`}
                        messageTitle={state.error.error}
                        message={state.error.message}
                    />
                </div>
            )}
            {state.accountForm && (
                <div>
                    <AccountNewModal updateHandler={getAccountDetails} />
                </div>
            )}
            {state.accountDetails && (
                <div>
                    <AccountDetails data={data} updateHandler={getAccountDetails} />
                </div>
            )}
        </>
    );
}

export default withAuthenticationRequired(AccountPage, {
    onRedirecting: () => <Loading />,
  });