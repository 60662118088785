 
const ProgramUpdateFormElements = [
    [
        {
            'label': 'Program Name',
            'labelKey': 'programName',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        },
    ],
];

export default ProgramUpdateFormElements;