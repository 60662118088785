  
const FulfilmentSubStatusNewFormElements = [
    [
        {
            'label': 'Sub Status Name',
            'labelKey': 'subStatusName',
            'required': 'true',
            'type' : 'input',
        },
    ],
];

export default FulfilmentSubStatusNewFormElements;