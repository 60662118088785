import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from '../../../pages/components/Loading';

const AccountDetails = ({updateHandler, data}) => {

    return (
        <>
            {data && (
                <Container fluid="md" className="mt-5">
                    <Row>
                        <Col>
                            <div className="card">
                                <div className="card-header">Application Account / Subscription Details</div>
                                <div className="card-body">
                                    <h5>Company / Enterprise Subscription Name : {data.companyName}</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );

};

export default withAuthenticationRequired(AccountDetails, {
    onRedirecting: () => <Loading />,
  });