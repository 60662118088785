import React, { useContext, useState } from 'react';
import WebForm from '../../html/WebForm';
import WebFormHeader from "../../html/WebFormHeader";
import NewClientFormElements from './NewClientFormElements';
import { AppConfigContext } from '../../config/AppConfig';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const NewClientModal = ({updateHandler}) => {

  const AppConfig = useContext(AppConfigContext);
  const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const APIEndPoint = `${URL}/client/`;

  const [isFormVisible, setIsFormVisible] = useState(false);

  const preFormSubmission = (data) => {
        console.log("preFormSubmission");
        console.log(data);
  };

  const postFormSubmission = (response) => {
        console.log("postFormSubmission");
        console.log(response);
        updateHandler();
  }

  const Form = {
        'title' : 'New Client',
        'restAPI' : APIEndPoint,
        'apiMethod' : 'POST',
        'elements' : NewClientFormElements,
        'preHandler' : preFormSubmission,
        'postHandler' : postFormSubmission
  };


  return (

    <div> {/* Changed container class */}
      <WebFormHeader title="Clients" label="+ New Client" onClick={() => setIsFormVisible(true)} />
      <br />
      <WebForm
        isVisible={isFormVisible}
        setIsVisible={setIsFormVisible}
        form={Form}
      /> {/* Refactored form content */}
    </div>
  );
};

export default withAuthenticationRequired(NewClientModal, {
  onRedirecting: () => <Loading />,
});