import { experienceList, priorityList } from "./DemandConfigData"

const DemandNewFormElements = [
    [
        {
            'label': 'Job Title',
            'labelKey': 'title',
            'required': 'true',
            'type' : 'input',
        },
        {
            'label': 'Role',
            'labelKey': 'role',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Client Name',
            'labelKey': 'clientId',
            'required': 'true',
            'type': 'select',
        },
        {
            'label': 'Location',
            'labelKey': 'location',
            'required': 'true',
            'type': 'input',
        },
    ],
    [
        {
            'label': 'Start Date',
            'labelKey': 'startDate',
            'required': 'true',
            'locale' : 'en-US',
            'dateFormat' : 'yyyy-MM-dd',
            'type': 'date',
        },
        {
            'label': 'No of Positions',
            'labelKey': 'positions',
            'required': 'true',
            'type': 'input',
        },
    ],
    [
        {
            'label': 'Demand Status',
            'labelKey': 'demandStatus',
            'required': 'true',
            'type': 'select',
        },
    ],
    [
        {
            'label': 'Job Description',
            'labelKey': 'jobDescription',
            'required': 'true',
            'type': 'textarea',
            'rows' : '12',
        },
    ],

];

export default DemandNewFormElements;