import React, { useEffect, useContext, useState } from 'react';
import WebForm from '../../html/WebForm';
import WebFormButton from '../../html/WebFormButton';

import NewFormElements from "./FulfilmentNewFormElements";
import ProfileDataService from '../../data/ProfileDataService';
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";



const FulfilmentNewModal = ({Id, Title, statusList, subStatusList, updateHandler}) => {
  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const APIEndPoint = `${URL}/fulfilment`;
  const {accessToken} = useAuthContext();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();

  const preFormSubmission = (data) => {
        console.log("preFormSubmission");
        data.demandId = `${Id}`;
        console.log(data);
  };

  const postFormSubmission = (response) => {
        console.log("postFormSubmission");
        console.log(response);
        updateHandler();
  }

  const Form = {
        'title' : 'New Profile Submission to Demand',
        'restAPI' : APIEndPoint,
        'apiMethod' : 'POST',
        'elements' : NewFormElements,
        'preHandler' : preFormSubmission,
        'postHandler' : postFormSubmission
  };

  const searchHandler = (keyword) => {
    return ProfileDataService.profileSearch(keyword,accessToken);
  };

  const setDependentOn = (value) => {

    console.log("Callback dependent list is ");
    console.log(subStatusList[value]);

    NewFormElements[1][1] = {
      ...NewFormElements[1][1],
      "options" : subStatusList[value],
    };

    setSelectedStatus(value);

  }

  useEffect(() => {

    NewFormElements[0][0] = {
      ...NewFormElements[0][0],
      "labelValue": Id,
    };

    NewFormElements[0][1] = {
      ...NewFormElements[0][1],
      "labelValue": Title,
    };

    NewFormElements[2][0] = {
      ...NewFormElements[2][0],
      "searchHandler" : searchHandler,
    };

   if(statusList) {
    NewFormElements[1][0] = {
      ...NewFormElements[1][0],
      "options" : statusList,
      "callback" : setDependentOn,
    };
  }

    if (subStatusList) {
      if (statusList && statusList.length > 0) {
        let firstElement = statusList[0].value;

        NewFormElements[1][1] = {
          ...NewFormElements[1][1],
          "options": subStatusList[firstElement],
        };
      }
    }

  },[Id,Title,statusList,subStatusList,isFormVisible]);

  return (

    <div> {/* Changed container class */}
      <WebFormButton label={"+"} onClick={() => setIsFormVisible(true)}  />
      <br />
      <WebForm
        isVisible={isFormVisible}
        setIsVisible={setIsFormVisible}
        form={Form}
      /> 
    </div>
  );
};

export default withAuthenticationRequired(FulfilmentNewModal, {
  onRedirecting: () => <Loading />,
});