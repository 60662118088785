import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import DemandList from './DemandList'
import DemandNewModal from './DemandNewModal';
import FulfilmentPage from '../fulfilment/FulfilmentPage';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppError from '../../../pages/AppError';

const DemandPage = () => {

    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

    const APIEndPoint = `${URL}/demand/`;
    const [data, setData] = useState([]);
    const [dataAPICall, setDataAPICall] = useState(0);
    const [clientList, setClientList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const {accessToken} = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getDemandStatusList = async () => {
        const APIEndPoint = `${URL}/demandstatus/`;
    
        let dataList = [];
    
        try {
            const response = await DataService.getAll(APIEndPoint, accessToken);

            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
                return;
            }
            
            dataList = response.data.map(Item => {
                return {
                    'value': Item.statusId,
                    'label': Item.statusName,
                }
            });
    
        }
        catch (error) {
            console.log("Demand Status list Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
    
        console.log("Demand Status List at DemandPage ");
        console.log(dataList)
    
        setStatusList(dataList);
    }
    
    const getClientList = async () => {
    
      const APIEndPoint = `${URL}/clients/`;
    
      let dataList = [];
    
      try {
          const response = await DataService.getAll(APIEndPoint,accessToken);
          if (!response) {
            const AppErrorMessage = {
                "status": 401,
                "error": "Request failed",
                "message": "Requested details not available"
            };
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage,
            });
            return;
        }

          dataList = response.data.map(Item => {
              return {
                  'value': Item.clientId,
                  'label': Item.clientName,
              }
          });
    
      }
      catch (error) {
          console.log("Client list Data fetch error");
          const AppErrorMessage = {
            "status": error.status,
            "error": error.response.data.error,
            "message": error.response.data.message
        };
        console.log(AppErrorMessage);
        setData(null);
        setState({
            ...state,
            showResult: false,
            loading: false,
            error: AppErrorMessage
        });
      }
    
      console.log("Client List at DemandPage ");
      console.log(dataList)
      setClientList(dataList);
    };

    const getLatestList = async () => {
        // Update data based on new submission (e.g., fetch new data or add new client)
        console.log("getLatestList started");
        try {
            const response = await DataService.getAll(APIEndPoint, accessToken);
            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
            } else {

                let demandList = response.data;
                demandList.map(demandItem => {
                    demandItem.id = demandItem.demandId;
                    demandItem.child = <FulfilmentPage
                        Id={demandItem.demandId}
                        Title={demandItem.title} />
                });

                setData(demandList);
                console.log("Demand List is ");
                console.log(data);
                setState({
                    ...state,
                    showResult: true,
                    loading: false,
                    error: null,
                });
            }
        }
        catch (error) {
            console.log("Demand list Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
        console.log("getLatestList completed");
    };

    useEffect(() => {

        console.log("Demand Page useEffect started : " + dataAPICall )

        getLatestList();
        getDemandStatusList();
        getClientList();

        console.log("Demand Page useEffect completed : " + dataAPICall)


    }, []);

        return (
            <>
             {state.loading && (
                    <div> <Loading /> </div>
                )}
                {state.error && (
                    <div>
                        <AppError 
                            headerTitle={`App Error - ${state.error.status}`} 
                            messageTitle={state.error.error}
                            message={state.error.message}
                        />
                    </div>
                )}
            {state.showResult && (
            <div>
                <DemandNewModal updateHandler={getLatestList}
                    clientList={clientList}
                    statusList={statusList}
                />
                <DemandList updateHandler={getLatestList}
                    data={data}
                    clientList={clientList}
                    statusList={statusList}
                    />
            </div>
            )}
            </>
        );
}

export default withAuthenticationRequired(DemandPage, {
    onRedirecting: () => <Loading />,
  });