  
const FulfilmentNewFormElements = [
    [
        {
            'label': 'Demand ID',
            'labelKey': 'demandId',
            'type' : 'label',
            'labelValue' : '',
        },
        {
            'label': 'Demand Title',
            'labelKey': 'demandTitle',
            'type' : 'label',
            'labelValue' : '',
        },
    ],
    [
        {
            'label': 'Status Name',
            'labelKey': 'fulfilmentStatus',
            'required': 'true',
            'type' : 'select',
        },
        {
            'label': 'Sub Status',
            'labelKey': 'fulfilmentSubStatus',
            'required': 'true',
            'type' : 'select',
        },
    ],
    [
        {
            'label': 'Applicant Name',
            'labelKey': 'profileName',
            'hiddenKey' : 'profileId',
            'required': 'false',
            'type' : 'dynamicsearch',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        },
    ],
];

export default FulfilmentNewFormElements;