import { AppConfig } from "../config/AppConfig";
import DataService from "../DataService";

class ProfileDataService {

  constructor() {
    // Consider adding a base URL for cleaner API endpoint construction
    this.baseUrl = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  }

  async getAll(accessToken, endpoint) {

    try {
      const response = await DataService.getAll(`${this.baseUrl}${endpoint}`, accessToken);
      if (!response) {
        throw new Error(`Profile Search API request failed with error ${response}`);
      }

      const data = response.data;
      return data.map((Item) => ({
        value: Item.profileId,
        label: `${Item.firstName} ${Item.lastName}`,
        left: `${Item.firstName} ${Item.lastName}`,
        right: Item.emailId,
      }));
    } catch (error) {
      console.error("Profile Search Data Fetch Error:", error);
      return null;
    }
  }

  async profileSearch(keyword, accessToken) {
    if (keyword.length > 1) {
      return await this.getAll(accessToken, `/profilesearch/${keyword}`);
    }
    return null; // Or return an empty array/placeholder if no keyword
  }
}

export default new ProfileDataService();