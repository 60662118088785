import React, { useContext, useState } from 'react';
import WebForm from '../../html/WebForm';
import WebFormHeader from "../../html/WebFormHeader";
import NewUserFormElements from './NewUserFormElements';
import { AppConfigContext } from '../../config/AppConfig';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";

const NewUserModal = ({updateHandler}) => {
  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const APIEndPoint = `${URL}/user/`;

  const [isFormVisible, setIsFormVisible] = useState(false);

  const preFormSubmission = (data) => {
        console.log("preFormSubmission");
        console.log(data);
  };

  const postFormSubmission = (response) => {
        console.log("postFormSubmission");
        console.log(response);
        updateHandler();
  }

  const Form = {
        'title' : 'New User',
        'restAPI' : APIEndPoint,
        'apiMethod' : 'POST',
        'elements' : NewUserFormElements,
        'preHandler' : preFormSubmission,
        'postHandler' : postFormSubmission
  };


  return (
    <div> {/* Changed container class */}
      <WebFormHeader title="Users" label="+ New User" onClick={() => setIsFormVisible(true)} />
      <br />
      <WebForm
        isVisible={isFormVisible}
        setIsVisible={setIsFormVisible}
        form={Form}
      /> 
    </div>
  );
};

export default withAuthenticationRequired(NewUserModal, {
  onRedirecting: () => <Loading />,
});