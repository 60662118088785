import { Container } from 'react-bootstrap'
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';

const Header = ({ head, description, statement}) => {
  return (
    <Container className="d-flex align-items-center justify-content-center">
      <div className='starter-template text-center mt-5'>
        <Image src="ats.png" fluid />;
        <h4>{head}<p><Badge bg="secondary" xs='sm'>beta</Badge></p></h4>
        <p className='lead text-capitalize'>{description}</p>
        <p className='lead'>{statement}</p>
      </div>
    </Container>
  )
}

export default Header
