import axios from 'axios';

const DataService = {

  getAll: (apiURL, token="") => {
    return axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error fetching data:', error.response.data);
        throw error;
      });
  },

  getById: (apiURL, token = "") => {
    return axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        const data = response.data;
        if (data && data.length > 0) {
          return data[0]; // Return the first element of the array
        } else {
          return null; // Handle case where data is empty or not an array
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error;
      });
  },

  deleteById: (apiURL, token="") => {
    return axios.delete(apiURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error;
      });
  }
}

export default DataService;