  
const DemandStatusNewFormElements = [
    [
        {
            'label': 'Demand Status Name',
            'labelKey': 'statusName',
            'required': 'true',
            'type' : 'input',
        },
    ],
];

export default DemandStatusNewFormElements;