import React, {useEffect, useContext, useState} from 'react';
import WebForm from '../../html/WebForm';
import WebFormHeader from "../../html/WebFormHeader";
import NewFormElements from './PortfolioNewFormElements';
import DataService from '../../DataService';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const PortfolioNewModal = ({updateHandler}) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const APIEndPoint = `${URL}/portfolio/`;

  const {accessToken} = useAuthContext();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {

    const fetchModalData = async () => {

      const APIEndPoint = `${URL}/clients/`;

      let dataList = [];

      try {
        const response = await DataService.getAll(APIEndPoint, accessToken);
        dataList = response.data.map(Item => {
          return {
            'value': Item.clientId,
            'label': Item.clientName,
          }
        });

      }
      catch {
        console.log("Client list Data fetch error");
      }

      console.log("Portfolio New Modal Data list ");
      setClientList(dataList)

      console.log("Portfolio New Modal Client list ");
      console.log(clientList);

    };

    fetchModalData();

    if (clientList && clientList.length > 0) {
      NewFormElements[0][0] = {
        'label': 'Client Name',
        'labelKey': 'clientId',
        'required': 'true',
        'type': 'select',
        'options': clientList,
      };
    }

    console.log("NewFormElements for Portfoio New Modal is ");
    console.log(NewFormElements);

  }, [isFormVisible]);

  const preFormSubmission = (data) => {
        console.log("preFormSubmission");
        console.log(data);
  };

  const postFormSubmission = (response) => {
        console.log("postFormSubmission");
        console.log(response);
        updateHandler();
  }


  const Form = {
        'title' : 'New Portfolio',
        'restAPI' : APIEndPoint,
        'apiMethod' : 'POST',
        'elements' : NewFormElements,
        'preHandler' : preFormSubmission,
        'postHandler' : postFormSubmission
  };

  return (

    <div> {/* Changed container class */}
      <WebFormHeader title="Portfolio" label="+ Portfolio" onClick={() => setIsFormVisible(true)} />
      <br />
      <WebForm
        isVisible={isFormVisible}
        setIsVisible={setIsFormVisible}
        form={Form}
      /> 
    </div>
  );
};

export default withAuthenticationRequired(PortfolioNewModal, {
  onRedirecting: () => <Loading />,
});