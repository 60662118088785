import React, { useEffect, useContext, useState } from 'react';
import WebForm from '../../html/WebForm';
import WebFormHeader from "../../html/WebFormHeader";
import NewFormElements from './DemandNewFormElements';
import { AppConfigContext } from '../../config/AppConfig';

import DataService from '../../DataService';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";

const DemandNewModal = ({updateHandler,clientList, statusList}) => {
  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const APIEndPoint = `${URL}/demand`;

  const [isFormVisible, setIsFormVisible] = useState(false);

  useEffect(() => {

    //console.log("DemandNewModel useEffect ");

    if (clientList && clientList.length > 0) {
      NewFormElements[1][0] = {
        'label': 'Client Name',
        'labelKey': 'clientId',
        'required': 'true',
        'type': 'select',
        'options': clientList,
      };
    } else {
      //console.log("CLient List is empty ")
    }

    if (statusList && statusList.length > 0) {
      NewFormElements[3][0] = {
        'label': 'Demand Status',
        'labelKey': 'demandStatus',
        'required': 'true',
        'type': 'select',
        'options': statusList,
      }
    } else {
      //  console.log("Demand Status list is empty");
    }


  }, [clientList,statusList,isFormVisible]);

  const preFormSubmission = (data) => {
        console.log("preFormSubmission");

        if(data.startDate) {
          const selectedDate = data.startDate;
          const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
          const day = selectedDate.getDate().toString().padStart(2, '0');
          const year = selectedDate.getFullYear();
          data.startDate = `${year}-${month}-${day}`;
        }

        console.log(data);
  };

  const postFormSubmission = (response) => {
        console.log("postFormSubmission");
        console.log(response);
        updateHandler();
  }

  const Form = {
        'title' : 'New Demand',
        'restAPI' : APIEndPoint,
        'apiMethod' : 'POST',
        'elements' : NewFormElements,
        'preHandler' : preFormSubmission,
        'postHandler' : postFormSubmission
  };


  return (

    <div> 
      <WebFormHeader title="Demand" label="+ New Demand" onClick={() => setIsFormVisible(true)} />
      <br />
      <WebForm
        isVisible={isFormVisible}
        setIsVisible={setIsFormVisible}
        form={Form}
      /> {/* Refactored form content */}
    </div>
  );
};

export default withAuthenticationRequired(DemandNewModal, {
  onRedirecting: () => <Loading />,
});