import React from 'react';
import './MessageDialog.css';


const MessageDialog = ({isVisible,
    setIsVisible,
    message}) => {

    const handleClose = () => {
        setIsVisible(false);
      };
  
      return (
        <div className={`message-dialog ${isVisible ? 'visible' : 'hidden'}`}>
          <div className="popup-content">
            <p className='message'>{message}</p>
            <button onClick={handleClose}>Close</button>
          </div>
        </div>
      );
};

export default MessageDialog;