import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import ProgramList from './ProgramList'
import ProjectPage from '../projects/ProjectPage';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppError from '../../../pages/AppError';

const ProgramPage = ({ PfId }) => {

    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

    const APIEndPoint = `${URL}/program/`;
    const [data, setData] = useState([]);
    const {accessToken} = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getLatestList = async () => {
        try {
            if (PfId) {
                const response = await DataService.getAll(`${APIEndPoint}${PfId}`, accessToken);

                if (!response) {
                    const AppErrorMessage = {
                        "status": 401,
                        "error": "Request failed",
                        "message": "Requested details not available"
                    };
                    setData(null);
                    setState({
                        ...state,
                        showResult: false,
                        loading: false,
                        error: AppErrorMessage,
                    });
                    return;
                }

                let dataList = response.data;

                dataList.map(dataItem => {
                    dataItem.id = dataItem.pfId;
                    dataItem.child = <ProjectPage ProgramId={dataItem.programId} />
                });
                setData(dataList);
                setState({
                    ...state,
                    showResult: true,
                    loading: false,
                    error: null,
                });
            } else {
                setData(null);
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
                return;
            }
        } catch (error) {
            console.log("Program Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
    };

    useEffect(() => {
        getLatestList();
    }, []);

    return (
        <>
            {state.loading && (
                <div> <Loading /> </div>
            )}
            {state.error && (
                <div>
                    <AppError
                        headerTitle={`App Error - ${state.error.status}`}
                        messageTitle={state.error.error}
                        message={state.error.message}
                    />
                </div>
            )}
            {state.showResult &&
                data ? (
                <div id="page">
                    <ProgramList
                        PfId={PfId}
                        updateHandler={getLatestList}
                        data={data} />
                </div>
            ) : null
            }
        </>
    );
}

export default withAuthenticationRequired(ProgramPage, {
    onRedirecting: () => <Loading />,
  });