import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import FulfilmentList from './FulfilmentList'

import FulfilmentStatusDataService from '../../data/FulfilmentStatusDataService';
import FulfilmentSubStatusDataService from '../../data/FulfilmentSubStatusDataService';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppError from '../../../pages/AppError';


const FulfilmentPage = ({Id, Title}) => {

    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

    const APIEndPoint = `${URL}/fulfilment/`;
    const [data, setData] = useState([]);
    const [statusList, setStatusList] = useState(null);
    const [subStatusList, setSubStatusList] = useState(null);
    const {accessToken} = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getStatusOptions = async() => {
       const statusOptions = await FulfilmentStatusDataService.getAllStatus(accessToken);
       setStatusList(statusOptions);
    }

    const getSubStatusOptions = async() => {
       const subStatusOptions = await FulfilmentSubStatusDataService.getAllStatusWithSubStatus(accessToken);
       setSubStatusList(subStatusOptions);
    }

    const getLatestList = async () => {
        try {
            if (Id) {
                const response = await DataService.getAll(`${APIEndPoint}${Id}`, accessToken);

                if (!response) {
                    const AppErrorMessage = {
                        "status": 401,
                        "error": "Request failed",
                        "message": "Requested details not available"
                    };
                    setData(null);
                    setState({
                        ...state,
                        showResult: false,
                        loading: false,
                        error: AppErrorMessage,
                    });
                    return;
                }

                setData(response.data);
                setState({
                    ...state,
                    showResult: true,
                    loading: false,
                    error: null,
                });
            } else {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
                return;
            }
        } catch (error) {
            console.log("Fulfilment Proposals Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
    }

    useEffect(() => {

        console.log("FulfilmentPage UseEffect: ")
        getLatestList();
        getStatusOptions();
        getSubStatusOptions();

    }, []);

    return (
        <>
            {state.loading && (
                <div> <Loading /> </div>
            )}
            {state.error && (
                <div>
                    <AppError
                        headerTitle={`App Error - ${state.error.status}`}
                        messageTitle={state.error.error}
                        message={state.error.message}
                    />
                </div>
            )}
            {state.showResult && data ? (
                <div id="fulfilmentwfpage">
                    <FulfilmentList
                        Id={Id}
                        Title={Title}
                        statusList={statusList}
                        subStatusList={subStatusList}
                        updateHandler={getLatestList}
                        data={data} />
                </div>
            ) : null
            }
        </>
    );
}

export default withAuthenticationRequired(FulfilmentPage, {
    onRedirecting: () => <Loading />,
  });