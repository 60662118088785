import React, {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import SearchForm from './SearchForm'
import WebInputWithSearchList from '../../html/WebInputWithSearchList';
import { titleList, roleList, countryList } from "./SearchFormData";
import "./Search.css";
import Autocomplete from '../../html/AutoComplete';

const SearchPage = () => {

    const { register, handleSubmit, reset, control } = useForm();
    const WebObj = {
        'label': 'Tite',
        'labelKey': 'userTitle',
        'required': 'true',
        'type': 'select',
        'options': titleList,
    };
    const suggestions = ['Apple', 'Banana', 'Orange', 'Pineapple', 'Grapes'];
    return (
        <div>
            <SearchForm />
        </div>
    );
}

export default SearchPage;