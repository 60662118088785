// ClientDeletePopup.js
import React, { useEffect,useContext, useState } from 'react';
import DataService from '../../DataService';
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


import "./SkillsDeleteModal.css";

const SkillsDeleteModal = ({ isVisible, setIsVisible, Id, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [skillData, setSkillData] = useState(null);
  const APIEndPoint = `${URL}/skills/`;
  const {accessToken} = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
          if (Id && isVisible) {
              let url = `${APIEndPoint}${Id}`;
              console.log("fetchData Response id " + Id);
              console.log("fetchData Response url " + url);
              const data = await DataService.getById(url,accessToken);
              console.log(data);
              setSkillData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [Id, isVisible]);

  const handleDelete = async () => {
    console.log("Proceeding to delete the skill " + Id);
    try {
      await DataService.deleteById(`${APIEndPoint}${Id}`, accessToken);
      console.log("Deletion completed for " + Id);
      setIsVisible(false);
      console.log("Calling onFresh to refresh the table");
      onFresh();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    console.log("Client Delete cancelled");
    setIsVisible(false);
  };

  return (
      skillData && isVisible ? (
        <div className="delete-popup-container">
        <div>
          <h2>Delete Skill</h2>
          <p>Are you sure you want to delete skill {skillData.skillName}?</p>
          <button onClick={handleDelete}>Delete</button>
          &nbsp;&nbsp;
          <button onClick={handleClose}>Cancel</button>
        </div>
        </div>
      ) :  null
  );
};

export default withAuthenticationRequired(SkillsDeleteModal, {
  onRedirecting: () => <Loading />,
});