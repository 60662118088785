
const SkillsUpdateFormElements = [
    [
        {
            'label': 'Skill Name',
            'labelKey': 'skillName',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        }
    ],

];

export default SkillsUpdateFormElements;