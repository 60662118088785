import { AppConfig } from "../config/AppConfig";
import DataService from "../DataService";

class FulfilmentSubStatusDataService {

  constructor() {
    this.baseUrl = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  }

  async getAll(accessToken, endpoint="/fulfilmentallsubstatus/") {

      try {
          let url = `${this.baseUrl}${endpoint}`;
          const response = await DataService.getAll(url, accessToken);

          if (!response) {
              throw new Error(`API request failed`);
          }

          return response.data;
      } catch (error) {
      console.error("Fulfilment Status Data Service error :", error);
      return null;
    }
  }

  async getAllStatusWithSubStatusV1(endpoint = "/fulfilmentallsubstatus/") {

    let StatusList = [];

      try {
          const response = await fetch(`${this.baseUrl}${endpoint}`);
          if (!response.ok) {
              throw new Error(`Fulfilment Sub Status API request failed with status ${response.status}`);
          }

          const records = await response.json();

          records.forEach(record => {

              if (!StatusList[record.statusName]) {
                  StatusList[record.statusName] = [];
              }

              StatusList[record.statusName].push({
                  "value": record.subStatusId,
                  "label": record.subStatusName
              });
          });

          console.log("sub ststus options are ");
          console.log(StatusList)
    } catch (error) {
      console.error("Profile Search Data Fetch Error:", error);
    }

    return StatusList;
}

  async getAllStatusWithSubStatus(accessToken) {

      let records = await this.getAll(accessToken);
      if (records) {

        let StatusSubStatusList = {};

        records.forEach(record => {

              if (!StatusSubStatusList[record.statusId]) {
                  StatusSubStatusList[record.statusId] = [];
              }

              StatusSubStatusList[record.statusId].push({
                  "value": record.subStatusId,
                  "label": record.subStatusName
              });
        });

        return StatusSubStatusList;

      }

    return null; // Or return an empty array/placeholder if no keyword
  }
}

export default new FulfilmentSubStatusDataService();