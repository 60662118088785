import React, {useState,useContext, useEffect} from 'react';
import DataService from "../../DataService";
import PortfolioNewModal from './PortfolioNewModal'
import PortfolioList from './PortfolioList'
import ProgramPage from '../programs/ProgramPage';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppError from '../../../pages/AppError';

//import ProgramsPage from '../programs/ProgramPage';

const PortfolioPage = () => {
    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

    const APIEndPoint = `${URL}/portfolio/`;
    const [data, setData] = useState([]);

    const {accessToken} = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getLatestList = async () => {
        try {
            const response = await DataService.getAll(APIEndPoint, accessToken);
            let dataList = response.data;

            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
                return;
            }

            dataList.map( dataItem => {
                dataItem.id = dataItem.pfId;
                dataItem.child = <ProgramPage PfId={dataItem.pfId} />
            });
            setData(dataList);
            setState({
                ...state,
                showResult: true,
                loading: false,
                error: null,
            });
        }
        catch(error) {
            console.log("Portfolio Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
    };

    useEffect(() => {
        getLatestList();
    }, []);

        return (
            <>
            {state.loading && (
                   <div> <Loading /> </div>
               )}
               {state.error && (
                   <div>
                       <AppError 
                           headerTitle={`App Error - ${state.error.status}`} 
                           messageTitle={state.error.error}
                           message={state.error.message}
                       />
                   </div>
               )}
           {state.showResult && (
            <div id="portfolilopage">
                <PortfolioNewModal updateHandler={getLatestList}/>
                <PortfolioList updateHandler={getLatestList} 
                data={data} />
            </div>
           )}
           </>
        );
}

export default withAuthenticationRequired(PortfolioPage, {
    onRedirecting: () => <Loading />,
  });