import WebTable from "../../html/WebTableDrill";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';   

import FulfilmentNewModal from "./FulfilmentNewModal";
import UpdateModal from "./FulfilmentUpdateModal";

const FulfilmentList = ({Id, Title, statusList, subStatusList, updateHandler, data}) => {

    const [selectedId, setSelectedId] = useState(null);
    const [demandTitle, setDemandTitle] = useState(null);
    const [selectedFulfilmentId, setSelectedFulfilmentId] = useState(null);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const handleUpdate = (rowData) => {
        console.log("Handle Fulfilment Workflow Update for Dmenad : " + Id + " with Fulfilment Id " + rowData.fulfilmentId)
        setSelectedId(Id);
        setDemandTitle(Title);
        setSelectedFulfilmentId(rowData.fulfilmentId);
        setIsUpdateActionPopupVisible(true);
    };

    const columns = {
        "profileName" : "Applicant Name",
        "emailId" : "Email ID",
        "fulfilmentStatus" : "Fulfilment Status",
        "fulfilmentSubStatus" : "Sub Status",
        "updatedBy" : "Updated By",
    };

    const headerActions = [
        {
            'action' : '+ Propose Profile',
            'jsxObject'  : <FulfilmentNewModal 
                                Id={Id} 
                                Title={Title} 
                                statusList={statusList}
                                subStatusList={subStatusList}
                                updateHandler={updateHandler}/>
        },
    ];

    const rowActions = [
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'fulfilmentId',
            'handler' : handleUpdate,
       },
    ];


    return (
        <div id="fulfilmentwflist">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                headerActions={headerActions}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                Id={selectedId}
                Title={demandTitle}
                statusList={statusList}
                subStatusList={subStatusList}
                fulfilmentId={selectedFulfilmentId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default FulfilmentList;