import WebFormButton from "./WebFormButton";
import "./WebFormHeader.css"

const WebFormHeader = ({title, label, onClick}) => {

    return (
        <div key={`${title}`} className='webform-header'>
            <label className='webform-header-label'>{title}</label>
            <WebFormButton label={label} onClick={onClick} />
            </div>
        );
};

export default WebFormHeader;