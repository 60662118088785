import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import DemandConfigNewModal from './DemandConfigNewModal'
import DemandConfigList from './DemandConfigList'
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppError from '../../../pages/AppError';

const DemandConfigPage = () => {

    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

    const APIEndPoint = `${URL}/demandconfigdetails/`;
    const [data, setData] = useState([]);
    const {accessToken} = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getLatestList = async () => {
        // Update data based on new submission (e.g., fetch new data or add new client)
        try {
            const response = await DataService.getAll(APIEndPoint, accessToken);
            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
            }else {
            setData(response.data);
            setState({
                ...state,
                showResult: true,
                loading: false,
                error: null,
            });
            }
        }catch(error) {
            console.log("DemandConfig list Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
    };

    useEffect(() => {
        getLatestList();
    }, []);

        return (
            <>
             {state.loading && (
                    <div> <Loading /> </div>
                )}
                {state.error && (
                    <div>
                        <AppError 
                            headerTitle={`App Error - ${state.error.status}`} 
                            messageTitle={state.error.error}
                            message={state.error.message}
                        />
                    </div>
                )}
            {state.showResult && (
            <div>
                <DemandConfigNewModal updateHandler={getLatestList}/>
                <DemandConfigList updateHandler={getLatestList}
                    data={data}/>
            </div>
            )}
            </>
        );
}

export default withAuthenticationRequired(DemandConfigPage, {
    onRedirecting: () => <Loading />,
  });