import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import DeleteModal from "../../html/DeleteModal";
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const ClientContactDeleteModal = ({ isVisible, setIsVisible, Id, contactId, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [modalData, setModalData] = useState(null);
  const APIEndPoint = `${URL}/contacts/`;
  const { accessToken } = useAuthContext();

  useEffect(() => {
    const fetchModalData = async () => {
      try {
          if (Id && contactId && isVisible) {
              const data = await DataService.getById(`${APIEndPoint}${Id}/${contactId}`, accessToken);
              console.log("fetchModalData for id " + Id);
              setModalData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchModalData();
  }, [Id, isVisible]);

  const handleDelete = async () => {
    console.log("Proceeding to delete the contact " + contactId);
    try {

      let url = `${APIEndPoint}${contactId}` ;
      console.log("Deletion url " + url );
      await DataService.deleteById(url, accessToken);
      console.log("Deletion completed for " + contactId);
      setIsVisible(false);
      console.log("Calling onFresh to refresh the table");
      onFresh();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    console.log("Delete Modal cancelled");
    setIsVisible(false);
  };

  return (
      modalData && isVisible ? (
        <DeleteModal 
                title='Delete Contact'
                message={`Are you sure you want to delete contact ${modalData.contactName} ?`}
                handleDelete={handleDelete}
                handleClose={handleClose}
        />
      ) :  null
  );
};

export default withAuthenticationRequired(ClientContactDeleteModal, {
  onRedirecting: () => <Loading />,
});