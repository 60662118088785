import { titleList, roleList, countryList, stateList, cityList } from "./SearchFormData";
 
const suggestions = ['Apple', 'Banana', 'Orange', 'Pineapple', 'Grapes'];

const SearchFormElements = [
    [
        {
            'label': 'Tite',
            'labelKey': 'userTitle',
            'required': 'true',
            'type' : 'select',
            'options' : titleList,
        },
    ],
    [
        {
            'label': 'First Name',
            'labelKey': 'firstName',
            'required': 'true',
            'type' : 'dynamicsearch',
        },
    ],
    [
        {
            'label': 'Last Name',
            'labelKey': 'lastName',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'State',
            'labelKey': 'stateCode',
            'required': 'true',
            'type' : 'select',
            'depends' : 'na',
            'options' : stateList,
        },
        {
            'label': 'City',
            'labelKey': 'cityCode',
            'required': 'true',
            'type' : 'select',
            'depends' : 'na',
            'options' : cityList["KR"],
        },
        {
            'label': 'Role',
            'labelKey': 'userRole',
            'required': 'true',
            'type' : 'select',
            'options' : roleList,
        },
    ],
    [
        {
            'label': 'Email ID',
            'labelKey': 'emailId',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        }
    ],
];

export default SearchFormElements;