import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import WebForm from '../../html/WebForm';
import UpdateFormElements from './ClientContactUpdateFormElements';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const ClientContactUpdateModal = ({ isVisible, setIsVisible, Id, contactId, onFresh }) => {

  const [modalData, setModalData] = useState(null);
  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const APIEndPoint = `${URL}/contacts/`;
  const {accessToken} = useAuthContext();

  const preFormSubmission = (data) => {
    console.log("preFormSubmission");
    data.clientId = `${Id}`;
};

const postFormSubmission = (response) => {
    console.log("postFormSubmission");
    onFresh();
}

const Form = {
    'title' : 'Update Client Contact',
    'restAPI' : APIEndPoint, 
    'apiMethod' : 'PUT',
    'elements' : UpdateFormElements,
    'preHandler' : preFormSubmission,
    'postHandler' : postFormSubmission
};

  useEffect(() => {
    const fetchModalData = async () => {
      try {
          if (Id && contactId && isVisible) {
              const data = await DataService.getById(`${APIEndPoint}${Id}/${contactId}`, accessToken);
              console.log("fetchModalData Response for id " + contactId);
              setModalData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchModalData();
  }, [Id,isVisible]);


  return (
      modalData && isVisible ? (
        <WebForm
            id={contactId}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            form={Form}
            formData={modalData}
            setFormData={setModalData}
        /> 
      ) :  null
  );
};

export default withAuthenticationRequired(ClientContactUpdateModal, {
  onRedirecting: () => <Loading />,
});