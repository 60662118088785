  
const FulfilmentUpdateFormElements = [
    [
        {
            'label': 'Demand ID',
            'labelKey': 'demandId',
            'required': 'true',
            'type' : 'label',
            'labelValue' : '',
        },
        {
            'label': 'Demand Title',
            'labelKey': 'demandTitle',
            'required': 'true',
            'type' : 'label',
            'labelValue' : '',
        },
    ],
    [
        {
            'label': 'Applicant Id',
            'labelKey': 'profileId',
            'required': 'true',
            'type' : 'label',
            "labelValue" : "",
        },
        {
            'label': 'Applicant Name',
            'labelKey': 'profileName',
            'required': 'true',
            'type' : 'label',
            "labelValue" : "",
        },
    ],
    [
        {
            'label': 'Status Name',
            'labelKey': 'fulfilmentStatusId',
            'required': 'true',
            'type' : 'select',
        },
        {
            'label': 'Sub Status',
            'labelKey': 'fulfilmentSubStatusId',
            'required': 'true',
            'type' : 'select',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        },
    ],
];

export default FulfilmentUpdateFormElements;