
const ClientContactNewFormElements = [
    [
        {
            'label': 'Contact Person Name',
            'labelKey': 'contactName',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        },
    ],
    [
        {
            'label': 'Designation / Title',
            'labelKey': 'designation',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        },
    ],
    [
        {
            'label': 'Mobile Number',
            'labelKey': 'mobileNumber',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        },
    ],
    [
        {
            'label': 'Email Id',
            'labelKey': 'emailId',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        }
    ],

];

export default ClientContactNewFormElements;