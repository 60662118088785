import WebTable from "../../html/WebTableDrill";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';   

import NewModal from "./ClientContactNewModal";
import DeleteModal from "./ClientContactDeleteModal"
import UpdateModal from "./ClientContactUpdateModal";

const ClientContactList = ({Id, updateHandler, data}) => {

    const [selectedId, setSelectedId] = useState(null);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const handleUpdate = (rowData) => {
        console.log("Handle Update : " + Id + " " + rowData.contactId)
        setSelectedId(Id);
        setSelectedContactId(rowData.contactId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle Delete : " + Id + " " + rowData.contactId)
        setSelectedId(Id);
        setSelectedContactId(rowData.contactId);
        setIsDeleteActionPopupVisible(true);
    };

    const columns = {
        "contactName" : "Contact Name",
        "designation" : "Title",
        "mobileNumber" : "Mobile Number",
        "emailId" : "emailId",
    };

    const headerActions = [
        {
            'action' : '+ New Contact',
            'jsxObject'  : <NewModal Id={Id} updateHandler={updateHandler}/>
        },
    ];

    const rowActions = [
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'contactId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'contactId',
            'handler' : handleDelete,
       } 
    ];


    return (
        <div id="clientcontactlist">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                headerActions={headerActions}
            />
            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                Id={selectedId}
                contactId={selectedContactId}
                onFresh={updateHandler}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                Id={selectedId}
                contactId={selectedContactId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default ClientContactList;