
const AccountNewFormElements = [
    [
        {
            'label': 'Your Company / Enterprise Account Name',
            'labelKey': 'accountName',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        },
    ]
];

export default AccountNewFormElements;