import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Container } from 'react-bootstrap';
// Layout
import Loading from "./pages/components/Loading";
import history from "./utils/history";
import Menu from "./pages/components/Menu";
import Footer from "./pages/components/Footer";

// pages
import Home from "./pages/Home";

import UserProfile from "./pages/Profile";
import {  useAuth0 } from "@auth0/auth0-react";

import Account from './components/admin/account/AccountPage'
import Users from './components/admin/user/UsersPage'
import Clients from './components/admin/client/ClientPage'
import DemandStatus from './components/admin/demandstatus/DemandStatusPage'
import FulfilmentStatus from './components/admin/fulfilmentstatus/FulfilmentStatusPage'
import Portfolio from './components/admin/portfolio/PortfolioPage'
import DemandConfig from './components/admin/demandconfig/DemandConfigPage'
import ProfileConfig from './components/admin/profileconfig/ProfileConfigPage'
import Skills from './components/admin/skills/SkillsPage'
import Proficiency from './components/admin/proficiency/ProficiencyPage'
import Profile from './components/admin/profile/ProfilePage'
import Demand from './components/admin/demands/DemandPage'
import TestPage from './components/admin/test/SearchPage'
import { AppConfigProvider } from './components/config/AppConfig';
import { AuthProvider } from "./components/config/AuthProvider";

import './App.css';

const App = () => {

	console.log = () => {};
	console.debug = () => {};
	console.info = () => {};
	console.warn = () => {};

	const {
		isLoading,
		error,
	} = useAuth0();

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	console.log("error : ", error);
	console.log("isLoading : ", isLoading);

	if (isLoading) {
		return <Loading />;
	} 

	return (
		<>
		<AuthProvider>
		<AppConfigProvider>
		<Router history={history}>
		<div id="app" className="d-flex flex-column h-100">
		<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" />
		<Container>
		<Menu />
		<Routes>
		<Route path="/" element={<Home />} exact />
		<Route path="/profile" element={<UserProfile />} />
		<Route path="/adm/account" element={<Account />} />
		<Route path="/adm/users" element={<Users />} />
		<Route path="/adm/demandstatus" element={<DemandStatus />} />
		<Route path="/adm/clients" element={<Clients />} />
		<Route path="/adm/fulfilmentstatus" element={<FulfilmentStatus />} />
		<Route path="/adm/portfolio" element={<Portfolio />} />
		<Route path="/adm/demandconfig" element={<DemandConfig />} />
		<Route path="/adm/profilesconfig" element={<ProfileConfig />} />
		<Route path="/adm/Skills" element={<Skills />} />
		<Route path="/adm/Proficiency" element={<Proficiency />} />
		<Route path="/adm/profiles" element={<Profile />} />
		<Route path="/adm/demands" element={<Demand />} />
		<Route path="/adm/testpage" element={<TestPage />} />
		</Routes>
		<Footer />
		</Container>
		</div>
		</Router>
		</AppConfigProvider>
		</AuthProvider>
		</>
	);
}

export default App;
