
const UpdateClientFormElements = [
    [
        {
            'labelKey': 'clientId',
            'required': 'true',
            'type' : 'hidden',
        },
    ],
    [
        {
            'label': 'Client Name',
            'labelKey': 'clientName',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        }
    ],

];

export default UpdateClientFormElements;