const WebTextArea = ({register, WebObj, defaultValue})  => {
        return (
            <textarea
                style={{ width: '100%' }}
                type="text"
                {...register(WebObj.labelKey, { required: WebObj.required === 'true' })}
                id={WebObj.labelKey}
                name={WebObj.labelKey}
                rows={WebObj.rows || '3'}
                defaultValue={defaultValue}
            ></textarea>
        );
};

export default WebTextArea;