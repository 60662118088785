const WebHiddenInput = ({register, WebObj, defaultValue})  => {
    return (
            <input
            type='hidden'
            id={WebObj.labelKey}
            name={WebObj.labelKey}
            defaultValue={defaultValue}
            {...register(WebObj.labelKey , { required: WebObj.required === 'true' })}
          />
    );
};

export default WebHiddenInput;