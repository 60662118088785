import Header from './components/Header'
import Meta from './components/Meta'
import NextStepsContent from './components/NextStepsContent'
import { Container } from 'react-bootstrap'


const Home = () => {
  // page content
  const pageTitle = 'Ingrainsys - ATS'
  const pageDescription = 'welcome to Ingrainsys SaaS Solution for Demand and Applicant Tracking System'
  const statement = "Simplify your hiring journey. From sourcing candidates to managing open demands and analyzing data, our all-in-one tool empowers you to hire the right people efficiently.";

  return (
    <Container>
    <div>
      <Meta title={pageTitle}/>
      <pr>&nbsp;</pr>
      <pr>&nbsp;</pr>
      <Header 
          head={pageTitle} 
          description={pageDescription} 
          statement={statement} />
      <pr>&nbsp;</pr>
      <pr>&nbsp;</pr>
    </div>
    <hr />
    <NextStepsContent />
    </Container>
  )
}

export default Home