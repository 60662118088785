import WebTable from "../../html/WebTableDrill";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';   

import ProgramNewModal from "./ProgramNewModal";
import DeleteModal from "./ProgramDeleteModal"
import UpdateModal from "./ProgramUpdateModal";

const ProgramList = ({PfId, updateHandler, data}) => {

    const [selectedPfId, setSelectedPfId] = useState(null);
    const [selectedPgmId, setSelectedPgmId] = useState(null);
    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const handleUpdate = (rowData) => {
        console.log("Handle Program Update : " + rowData.programId)
        setSelectedPfId(PfId);
        setSelectedPgmId(rowData.programId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle Program Delete : " + PfId + " " + rowData.programId)
        setSelectedPfId(PfId);
        setSelectedPgmId(rowData.programId);
        setIsDeleteActionPopupVisible(true);
    };


    const columns = {
        "programName" : "Program Name",
    };

    const headerActions = [
        {
            'action' : '+ New Program',
            'jsxObject'  : <ProgramNewModal PfId={PfId} updateHandler={updateHandler} />
        },
    ];

    const rowActions = [
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'programId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'programId',
            'handler' : handleDelete,
       } 
    ];


    return (
        <div id="programlist">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                headerActions={headerActions}
            />
            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                PfId={selectedPfId}
                pgmId={selectedPgmId}
                onFresh={updateHandler}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                PfId={selectedPfId}
                pgmId={selectedPgmId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default ProgramList;