import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import FulfilmentStatusNewModal from './FulfilmentStatusNewModal'
import FulfilmentStatusList from './FulfilmentStatusList'
import { AppConfigContext } from '../../config/AppConfig';

import FulfilmentSubStatusPage from '../fulfilmentsubstatus/FulfilmentSubStatusPage';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppError from '../../../pages/AppError';

const FulfilmentStatusPage = () => {
    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

    const APIEndPoint = `${URL}/fulfilmentstatus/`;
    const [data, setData] = useState([]);
    const {accessToken} = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getLatestList = async () => {
        try {

            const response = await DataService.getAll(APIEndPoint, accessToken);

            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
                return;
            }

            let statusList = response.data;
            statusList.map( statusItem => {
                statusItem.id = statusItem.statusId;
                statusItem.child = <FulfilmentSubStatusPage Id={statusItem.statusId} />
            });

            console.log("Status List is ");
            console.log(statusList);
            setData(statusList);
            setState({
                ...state,
                showResult: true,
                loading: false,
                error: null,
            });
        }
        catch (error) {
            console.log("Fulfilment Status Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
    };

    useEffect(() => {
        getLatestList();
    }, []);

    return (
        <>
            {state.loading && (
                <div> <Loading /> </div>
            )}
            {state.error && (
                <div>
                    <AppError
                        headerTitle={`App Error - ${state.error.status}`}
                        messageTitle={state.error.error}
                        message={state.error.message}
                    />
                </div>
            )}

            {state.showResult && (
                <div id="fulfilmentstatuspage">
                    <FulfilmentStatusNewModal updateHandler={getLatestList} />
                    <FulfilmentStatusList updateHandler={getLatestList}
                        data={data} />
                </div>
            )}
        </>
    );
}

export default withAuthenticationRequired(FulfilmentStatusPage, {
    onRedirecting: () => <Loading />,
  });