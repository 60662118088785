import DataService from "../DataService";
import { AppConfig } from "../config/AppConfig";

class FulfilmentStatusDataService {

    constructor() {
        this.baseUrl = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
    }

    async getAll(accessToken, endpoint = "/fulfilmentstatus/") {

        try {
            let url = `${this.baseUrl}${endpoint}`;
            const response = await DataService.getAll(url, accessToken);

            if (!response) {
                throw new Error(`API request failed`);
            }
            return response.data;
        } catch (error) {
            console.error("Fulfilment Status Data Service error :", error);
            return null;
        }
    }

    async getAllStatus(accessToken) {

        let StatusList = [];
        let records = await this.getAll(accessToken);

        if (records) {
          records.forEach(record => {
                StatusList.push({
                    "value": record.statusId,
                    "label": record.statusName
                });
          });
          return StatusList;
        }
    }

    getAllStatusV2(endpoint = "/fulfilmentstatus/") {

        let StatusList = [];

        return fetch(`${this.baseUrl}${endpoint}`)
            .then(response => { response.json() })
            .then(records => {

                if (records) {
                    records.forEach(record => {
                        StatusList.push({
                            "value": record.statusId,
                            "label": record.statusName
                        });
                    });
                }

                return StatusList;
            })
            .catch((error) => {
                console.error("Fulfilment Data Fetch Error:", error);
                return StatusList;
            });
    }

}

export default new FulfilmentStatusDataService();