import React, { useState, useMemo, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';

const WebDatePicker = ({ register, WebObj, defaultValue, control }) => {
  const { labelKey, required, dateFormat, locale } = WebObj; // Destructure for readability
  const [selectedDate, setSelectedDate] = useState(defaultValue);

  // Register locale if provided
  useEffect(() => {
    if (locale) {
      registerLocale(locale);
    }
  }, [locale]);

  const formattedDate = useMemo(() => {
    if (!selectedDate) return null;
    console.log("Selected and Formatted Date is " + selectedDate);
    return moment(selectedDate).format(dateFormat || 'yyyy-MM-dd');
  }, [selectedDate, dateFormat]);

  const handleChange = (date) => {
    console.log("Selected date " + date);
    setSelectedDate(date);
  };

  return (
    <Controller
      name={`${labelKey}`}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <DatePicker
          selected={field.value}
          onChange={field.onChange}
          dateFormat={dateFormat || 'yyyy-MM-dd'}
          id={`${labelKey}`}
          customInput={<input
            style={{ width: '100%' }}
            value={formattedDate}
            type="text"
            {...register(labelKey , { required: required === 'true' })}
            readOnly
          />}
        />
      )}
    />
  );
};

export default WebDatePicker;