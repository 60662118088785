
const DemandConfigUpdateFormElements = [
    [
        {
            'label': 'Demand Form Parameter Key',
            'labelKey': 'detailKey',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        }
    ],
];

export default DemandConfigUpdateFormElements;