  
const FulfilmentSubStatusUpdateFormElements = [
    [
        {
            'label': 'Fulfilment Sub Status ',
            'labelKey': 'subStatusName',
            'required': 'true',
            'type' : 'input',
        },
    ],
];

export default FulfilmentSubStatusUpdateFormElements;