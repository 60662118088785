import React, { useState, useEffect } from 'react';
import "./WebTable.css"; // Assuming you have a CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from   
 '@fortawesome/free-solid-svg-icons';

const WebTable = ({
  columns,
  data,
  rowActions,
  headerActions,
  rowsPerPage = 20,
  currentPage = 1,
  onPageChange,
  onSort = false,
  onSearch = false,
  onPageNavigation = false,
}) => {

  const [currentPageState, setCurrentPageState] = useState(currentPage);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [searchTerms, setSearchTerms] = useState(
    Object.keys(columns).fill('')
  ); // Initialize search terms for all columns
  const [visibleChildTable, setVisibleChildTable] = useState({});

  const handleSearchTermChange = (columnIndex, event) => {
    const updatedSearchTerms = [...searchTerms];
    updatedSearchTerms[columnIndex] = event.target.value;
    setSearchTerms(updatedSearchTerms);
  };

  const filteredData = () => {

    let filtered = data ? data.slice() : [];

    // Apply column-wise search filters
    filtered = filtered.filter((item) => {
      return Object.keys(columns).every((colKey, index) => {
        const searchTerm = searchTerms[index].toLowerCase();
        const itemValue = item[colKey] ? item[colKey].toLowerCase() : '';
        return searchTerm === '' || itemValue.includes(searchTerm);
      });
    });

    // Apply sorting after filtering
    filtered = filtered.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (valueA < valueB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return filtered.slice((currentPageState - 1) * rowsPerPage, currentPageState * rowsPerPage);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  
  const totalPages = Math.ceil(data ? ( data.length / rowsPerPage) : 0 );

  const handlePrev = () => {
    if (currentPageState > 1) {
      setCurrentPageState(currentPageState - 1);
      onPageChange && onPageChange(currentPageState - 1);
    }
  };

  const handleNext = () => {
    if (currentPageState < totalPages) {
      setCurrentPageState(currentPageState + 1);
      onPageChange && onPageChange(currentPageState + 1);
    }
  };

  const WebTableSearch= () => {

      return (
          onSearch === true && (
              <tr>
                  {Object.keys(columns).map((colKey, index) => (
                      <th key={colKey}>
                          <input
                              type="text"
                              placeholder="Search"
                              value={searchTerms[index]}
                              onChange={(event) => handleSearchTermChange(index, event)}
                          />
                      </th>
                  ))}
                  <th key="searchactions"></th>
              </tr>
          )
      );

  };

  const WebTableHeading = () => {

      return (
          <tr>
              {Object.keys(columns).map((colKey, index) => (
                  <th key={colKey}>
                      {columns[colKey]}&nbsp;
                      {onSort && (
                          <span className="sort-icon" onClick={() => handleSort(colKey)}>
                              (
                              <i className={`fa fa-caret-${sortDirection === 'asc' ? 'up' : 'down'}`}></i>
                              )
                          </span>
                      )}
                  </th>
              ))}
              <th key="actions" style={{ textAlign: 'right' }}>
                  {headerActions && headerActions.map((action) => (
                        action.jsxObject
                  ))}
              </th>
          </tr>
      );
  };

  const WebTableNavigation = () => {

        return (
            onPageNavigation === true && (
                <tr>
                    <td colSpan={Object.keys(columns).length + 1} style={{ textAlign: 'right' }}>
                        <button key='prev' className='webtable-button' onClick={handlePrev} disabled={currentPageState === 1}>
                            Prev
                        </button>
                        &nbsp;
                        <span>
                            {currentPageState} out of {totalPages}
                        </span>
                        &nbsp;
                        <button key='next' className='webtable-button' onClick={handleNext} disabled={currentPageState === totalPages}>
                            Next
                        </button>
                    </td>
                </tr>
            )

        );
  };

  const WebTableData = () => {

      return (
          filteredData().map((item) => (
              WebRow(item)
          ))
      );

  };

    const WebRow = (rowData) => {
        const isChildTableVisible = visibleChildTable[rowData.id];

        return (
            <>
                <tr key={rowData.id || Math.random()}>
                    {
                     Object.keys(columns).map((colName, index) => (
                        <td key={`${rowData.id}-${colName}`}>{rowData[colName]}</td>
                    ))}
                    <td key={`${rowData.id}-actions`} style={{ textAlign: 'right' }} > 
                        {rowActions && rowActions.map((action) => (
                            <button key={`${action.action}-${rowData.id}`} className="webtable-button" onClick={() => action.handler(rowData)} >
                                {action.icon}
                            </button>
                        ))}
                        {rowData.child && (
                            <button key={`${rowData.id}-child`} className="webtable-button" onClick={() => setVisibleChildTable((prevVisibleChildTable) => ({ ...prevVisibleChildTable, [rowData.id]: !prevVisibleChildTable[rowData.id] }))}>
                                {isChildTableVisible ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                            </button>
                        )}
                    </td>
                </tr>
                {isChildTableVisible && rowData.child && (
                    <tr>
                        <td colSpan={Object.keys(columns).length+1}>{rowData.child}</td>
                    </tr>
                )}
            </>
        );

    };

  useEffect(() => {
    // Update filtered data when search terms change
      if(!data)
      { 
        data = [];
      }
      
    filteredData();
  }, [searchTerms]);

  return (
    <table className="WebTable">
      <thead>
        { WebTableSearch() }
        { WebTableHeading() }
      </thead>
      <tbody>
        { WebTableData() }
      </tbody>
      <tfoot>
        { WebTableNavigation() }
      </tfoot>
    </table>
  );
};

export default WebTable;
