import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import DeleteModal from "../../html/DeleteModal";
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';

import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const DeleteUserModal = ({ isVisible, setIsVisible, Id, onFresh }) => {
  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const { accessToken } = useAuthContext();

  const [modalData, setModalData] = useState(null);
  const APIEndPoint = `${URL}/user/`;

  useEffect(() => {
    const fetchModalData = async () => {
      try {
          if (Id && isVisible) {
              const data = await DataService.getById(APIEndPoint + Id, accessToken);
              console.log("fetchModalData for id " + Id);
              setModalData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchModalData();
  }, [Id, isVisible]);

  const handleDelete = async () => {
    console.log("Proceeding to delete the user " + Id);
    try {
      await DataService.deleteById(APIEndPoint + Id, accessToken);
      console.log("Deletion completed for " + Id);
      setIsVisible(false);
      console.log("Calling onFresh to refresh the table");
      onFresh();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    console.log("Delete Modal cancelled");
    setIsVisible(false);
  };

  return (
      modalData && isVisible ? (
        <DeleteModal 
                title='Delete Client'
                message={`Are you sure you want to delete user ${modalData.firstName} ${modalData.lastName} ?`}
                handleDelete={handleDelete}
                handleClose={handleClose}
        />
      ) :  null
  );
};

export default withAuthenticationRequired(DeleteUserModal, {
  onRedirecting: () => <Loading />,
});