// ClientDeletePopup.js
import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import "./DeleteClientModal.css";
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from '../../../pages/components/Loading';

const DeleteClientModal = ({ isVisible, setIsVisible, clientId, onFresh }) => {

  const [clientData, setClientData] = useState(null);
  const AppConfig = useContext(AppConfigContext);
  const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const APIEndPoint = `${URL}/client/`;
  const {accessToken} = useAuthContext();

  useEffect(() => {
    const fetchClientData = async () => {
      try {
          if (clientId && isVisible) {
              const data = await DataService.getById(APIEndPoint + clientId, accessToken);
              console.log("fetchClientData Response for id " + clientId);
              setClientData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchClientData();
  }, [clientId, isVisible]);

  const handleDelete = async () => {
    console.log("Proceeding to delete the client " + clientId);
    try {
      await DataService.deleteById(APIEndPoint + clientId, accessToken);
      console.log("Deletion completed for " + clientId);
      setIsVisible(false);
      console.log("Calling onFresh to refresh the table");
      onFresh();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    console.log("Client Delete cancelled");
    setIsVisible(false);
  };

  return (
      clientData && isVisible ? (
        <div className="delete-popup-container">
        <div>
          <h2>Delete Client</h2>
          <p>Are you sure you want to delete client {clientData.clientName}?</p>
          <button onClick={handleDelete}>Delete</button>
          &nbsp;&nbsp;
          <button onClick={handleClose}>Cancel</button>
        </div>
        </div>
      ) :  null
  );
};

export default withAuthenticationRequired(DeleteClientModal, {
  onRedirecting: () => <Loading />,
});