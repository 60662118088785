import "./WebFormButton.css";

const WebFormButton = ( {label, onClick}) => {

  return (
            <button className="webform-button" onClick={onClick}>
              {label}
            </button>
  );

}

export default WebFormButton;