import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import "./WebInputDynamicSearchList.css";

const WebInputWithDynamicSearchList = ({ register, WebObj, setValue, defaultValue }) => {

  const { labelKey, placeholder, required } = WebObj;
  //const { setValue } = useForm();
  const [options, setSearchOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const inputRef = useRef(null);

  // Function to filter options based on search term
  const filterOptions = (term) => {
    console.log("Filter Term : " + term);
    if (options && options.length > 0) {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  // Handle user input changes
  const handleInputChange = async (event) => {
    console.log("handleInputChange Called! New Value:", event.target.value);
    console.log(event);
    const newTerm = event.target.value;
    setSearchTerm(newTerm);
    setValue(WebObj.labelKey,newTerm);

    let results = await WebObj.searchHandler(newTerm).then(results => {
        if (results) {
          console.log("then results are : ");
          console.log(results);
          setSearchOptions(results);
        }
        return results;
      });

    console.log("Updated Search Results in Options are : " + newTerm);
    console.log(options);

    filterOptions(newTerm);

  };

  // Handle list item selection (optional)
  const handleListItemClick = (option) => {
    console.log("handleListItemClick " + option.label + option.value);
    setSearchTerm(option.label);
    setValue(WebObj.labelKey,option.label);
    setValue(WebObj.hiddenKey,`${option.value}`);

    setFilteredOptions([]); // Hide list on selection
  };

  // Close the list when clicking outside the component (optional)
  useEffect(() => {
    console.log("WebInputDSL useEffect : " + searchTerm);

    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setFilteredOptions([]);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

  }, [inputRef]);

  const listItems = filteredOptions.map((option) => (
    <li className="autocomplete-suggestion" key={option.value} onClick={() => handleListItemClick(option)}>
      <span className='left'>{option.left}</span>
      <span className='right'>{option.right}</span>
    </li>
  ));

  return (
    <div key='web-search' className="autocomplete-div">
      <input
        {...register(WebObj.labelKey, { required: WebObj.required === 'true' })}
        ref={inputRef}
        className="autocomplete-input"
        type="text"
        id={labelKey}
        name={labelKey}
        value={searchTerm}
        onChange={handleInputChange}
      />
      {filteredOptions.length > 0 && (
        <ul className="autocomplete-suggestions">
          {listItems}
        </ul>
      )}
    </div>
  );
};

export default WebInputWithDynamicSearchList;