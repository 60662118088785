  
const FulfilmentStatusUpdateFormElements = [
    [
        {
            'label': 'Fulfilment Status Name',
            'labelKey': 'statusName',
            'required': 'true',
            'type' : 'input',
        },
    ],
];

export default FulfilmentStatusUpdateFormElements;