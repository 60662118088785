import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import WebForm from '../../html/WebForm';
import UpdateFormElements from './ProgramUpdateFormElements';
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const ProgramUpdateModal = ({ isVisible, setIsVisible, PfId, pgmId, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [modalData, setModalData] = useState(null);
  const APIEndPoint = `${URL}/program/`;
  const {accessToken} = useAuthContext();

  const preFormSubmission = (data) => {
    console.log("preFormSubmission");
    data.pfId = `${PfId}`;
};

const postFormSubmission = (response) => {
    console.log("postFormSubmission");
    onFresh();
}

const Form = {
    'title' : 'Update Fulfilment Status',
    'restAPI' : APIEndPoint,
    'apiMethod' : 'PUT',
    'elements' : UpdateFormElements,
    'preHandler' : preFormSubmission,
    'postHandler' : postFormSubmission
};

  useEffect(() => {
    const fetchModalData = async () => {
      try {
          if (PfId && isVisible) {
              const data = await DataService.getById(`${APIEndPoint}${PfId}/${pgmId}`, accessToken);
              console.log("fetchModalData Response for id " + pgmId);
              setModalData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchModalData();
  }, [PfId,isVisible]);


  return (
      modalData && isVisible ? (
        <WebForm
            id={pgmId}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            form={Form}
            formData={modalData}
            setFormData={setModalData}
        /> 
      ) :  null
  );
};

export default withAuthenticationRequired(ProgramUpdateModal, {
  onRedirecting: () => <Loading />,
});