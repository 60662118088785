import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import NewClientModal from './NewClientModal'
import ClientList from './ClientList'
import ClientContactPage from '../clientcontacts/ClientContactPage';
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from '../../../pages/components/Loading';
import AppError from '../../../pages/AppError';

const ClientPage = () => {

    const AppConfig = useContext(AppConfigContext);

    const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
    const APIEndPoint = `${URL}/clients/`;
    const [data, setData] = useState([]);
    const { accessToken } = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getLatestClientList = async () => {
        // Update data based on new submission (e.g., fetch new data or add new client)
        try {
            const response = await DataService.getAll(APIEndPoint, accessToken);

            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
                return;
            }

            let dataList = response.data;
            dataList.map( dataItem => {
                dataItem.id = dataItem.clientId;
                dataItem.child = <ClientContactPage Id={dataItem.clientId} />
            });

            console.log("Client List is ");
            console.log(dataList);
            setData(dataList);
            setState({
                ...state,
                showResult: true,
                loading: false,
                error: null,
            });
        } catch (error) {
            console.log("Client list Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
    };

    useEffect(() => {
        getLatestClientList();
    }, []);

        return (
            <>
             {state.loading && (
                    <div> <Loading /> </div>
                )}
                {state.error && (
                    <div>
                        <AppError 
                            headerTitle={`App Error - ${state.error.status}`} 
                            messageTitle={state.error.error}
                            message={state.error.message}
                        />
                    </div>
                )}
            {state.showResult && (
            <div>
                <NewClientModal updateHandler={getLatestClientList}/>
                <ClientList updateHandler={getLatestClientList}
                    data={data}/>
            </div>
            )}
            </>
        );
}

export default withAuthenticationRequired(ClientPage, {
    onRedirecting: () => <Loading />,
  });