import WebTable from "../../html/WebTableDrill";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';   

import FulfilmentSubStatusNewModal from "./FulfilmentSubStatusNewModal";
import DeleteModal from "./FulfilmentSubStatusDeleteModal"
import UpdateModal from "./FulfilmentSubStatusUpdateModal";

const FulfilmentSubStatusList = ({Id, updateHandler, data}) => {

    const [selectedId, setSelectedId] = useState(null);
    const [selectedSubStatusId, setSelectedSubStatusId] = useState(null);
    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const handleUpdate = (rowData) => {
        console.log("Handle sub Status Update : " + Id + " " + rowData.subStatusId)
        setSelectedId(Id);
        setSelectedSubStatusId(rowData.subStatusId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle sub Status Delete : " + Id + " " + rowData.subStatusId)
        setSelectedId(Id);
        setSelectedSubStatusId(rowData.subStatusId);
        setIsDeleteActionPopupVisible(true);
    };


    const columns = {
        "subStatusName" : "Fulfilment Sub Status ",
    };

    const headerActions = [
        {
            'action' : '+ New Sub Status',
            'jsxObject'  : <FulfilmentSubStatusNewModal Id={Id} updateHandler={updateHandler}/>
        },
    ];

    const rowActions = [
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'subStatusId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'subStatusId',
            'handler' : handleDelete,
       } 
    ];


    return (
        <div id="fulfilmentsubstatuslist">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                headerActions={headerActions}
            />
            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                Id={selectedId}
                subStatusId={selectedSubStatusId}
                onFresh={updateHandler}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                Id={selectedId}
                subStatusId={selectedSubStatusId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default FulfilmentSubStatusList;