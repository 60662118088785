import WebTable from "../../html/WebTableDrill";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';   

import DeleteModal from "./FulfilmentStatusDeleteModal";
import UpdateModal from "./FulfilmentStatusUpdateModal";

const FulfilmentStatusList = ({updateHandler, data}) => {


    const [selectedId, setSelectedId] = useState(null);
    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const handleUpdate = (rowData) => {
        console.log("Handle Update : " + rowData.statusId)
        setSelectedId(rowData.statusId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle Delete : " + rowData.statusId)
        setSelectedId(rowData.statusId);
        setIsDeleteActionPopupVisible(true);
    };


    const columns = {
        "statusName" : "Fulfilment Status Name",
    };

    const rowActions = [
       {
            'action' : 'Edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'statusId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'statusId',
            'handler' : handleDelete,
       },
    ];


    return (
        <div id="fulfilmentstatuslist">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                onSort={true}
                onSearch={true}
                onPageNavigation={true}
            />
            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default FulfilmentStatusList;