import { titleList, roleList, countryList } from "./ConfigData";
  
const NewUserFormElements = [
    [
        {
            'label': 'Tite',
            'labelKey': 'userTitle',
            'required': 'true',
            'type' : 'select',
            'options' : titleList,
        },
        {
            'label': 'First Name',
            'labelKey': 'firstName',
            'required': 'true',
            'type' : 'input',
        },
        {
            'label': 'Last Name',
            'labelKey': 'lastName',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Country',
            'labelKey': 'countryCode',
            'required': 'true',
            'type' : 'select',
            'options' : countryList,
        },
        {
            'label': 'Mobile Number',
            'labelKey': 'mobileNumber',
            'required': 'true',
            'type' : 'input',
        },
        {
            'label': 'Role',
            'labelKey': 'userRole',
            'required': 'true',
            'type' : 'select',
            'options' : roleList,
        },
    ],
    [
        {
            'label': 'Email ID',
            'labelKey': 'emailId',
            'required': 'true',
            'type' : 'input',
        },
        {
            'type' : 'empty',
        }
    ],
];

export default NewUserFormElements;