import React, { useState, useEffect, useRef } from 'react';
import "./WebInputWithSearchList.css";

const WebInputWithSearchList = ({ register, WebObj, setValue, defaultValue }) => {
  const { labelKey, placeholder, options, required } = WebObj;
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const inputRef = useRef(null);

  // Function to filter options based on search term
  const filterOptions = (term) => {
    console.log("Filter Term : " + term);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  // Handle user input changes
  const handleInputChange = (event) => {
    console.log("handleInputChange Called! New Value:", event.target.value);
    console.log(event);
    const newTerm = event.target.value;
    setSearchTerm(newTerm);
    setValue(WebObj.labelKey,newTerm);
    filterOptions(newTerm);

  };

  // Handle list item selection (optional)
  const handleListItemClick = (option) => {
    console.log("handleListItemClick " + option.label);
    setSearchTerm(option.label);
    setValue(WebObj.labelKey,option.label);
    setValue(WebObj.hiddenKey,`${option.value}`);
    setFilteredOptions([]); // Hide list on selection
  };


  // Close the list when clicking outside the component (optional)
  useEffect(() => {

    console.log("useEffect : " + searchTerm);
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setFilteredOptions([]);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

  }, [inputRef]);

  const listItems = filteredOptions.map((option) => (
    <li className="autocomplete-suggestion" key={option.value} onClick={() => handleListItemClick(option)}>
      {option.label}
    </li>
  ));

    // Show a message if there are no search results
    const noResultsMessage = filteredOptions.length === 0 && searchTerm !== '' ? (
      <p>No results found for "{searchTerm}"</p>
    ) : null;

  return (
    <div key='web-search' className="autocomplete-div">
      <input
        {...register(WebObj.labelKey, { required: WebObj.required === 'true' })}
        ref={inputRef}
        className="autocomplete-input"
        type="text"
        id={labelKey}
        name={labelKey}
        value={searchTerm}
        onChange={handleInputChange}
      />
      {filteredOptions.length > 0 && (
        <ul className="autocomplete-suggestions">
          {listItems}
        </ul>
      )}
    </div>
  );
};

export default WebInputWithSearchList;