import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink as RouterNavLink } from "react-router-dom";

import React  from "react";

import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { useAuth0 } from "@auth0/auth0-react";

const Menu = () => {

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();


  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

  const NavigationMenu = () => {
    return (
        <Navbar expand='lg' className="justify-content-start">
        <Navbar.Brand as={Link} to="/">Ingrainsys</Navbar.Brand>
        { isAuthenticated && 
        (
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="ATS Admin" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/adm/account">Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/adm/users">Users</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/clients">Clients</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/portfolio">Portfolio</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/demandstatus">Demand Status</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/fulfilmentstatus">Fulfilment Status</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/profilesconfig">Profile Config</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/demandconfig">Demand Config</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/skills">Skills</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/proficiency">Proficiency</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Demands Management" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/adm/profiles">Profiles</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/adm/demands">Demands</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </Navbar.Collapse>
        </Container>
        ) }
      </Navbar>
    );
  };

  const Profile = () => {
    return (
      <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret id="profileDropDown">
        <img
          src={user.picture}
          alt="Profile"
          className="nav-user-profile rounded-circle"
          width="50"
        />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>{user.name}</DropdownItem>
        <DropdownItem
          tag={RouterNavLink}
          to="/profile"
          className="dropdown-profile"
          activeClassName="router-link-exact-active"
        >
          <FontAwesomeIcon icon="user" className="mr-3" /> Profile
        </DropdownItem>
        <DropdownItem
          id="qsLogoutBtn"
          onClick={() => logoutWithRedirect()}
        >
          <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
          out
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    )
  }


  const SignIn = () => {
      return (
        <Nav>
        <div className="text-end">
          <Link to="/signin">
            <Button 
                variant="primary" 
                size="sm"  
                style={{ backgroundColor: '#007bff' }}
                onClick={() => loginWithRedirect()}
                >
               Sign In
            </Button>
           </Link>
        </div>
        </Nav>
      );
  }


  return (
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        { NavigationMenu() }
        { isAuthenticated ? Profile() : SignIn() }
      </header>
    </Container>
  );
};

export default Menu;
