import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import DeleteModal from "../../html/DeleteModal";
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const PortfolioDeleteModal = ({ isVisible, setIsVisible, clientId, pfId, onFresh }) => {
  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [modalData, setModalData] = useState(null);
  const APIEndPoint = `${URL}/portfolio/`;
  const {accessToken} = useAuthContext();

  useEffect(() => {
    const fetchModalData = async () => {
      try {
          if (clientId && pfId && isVisible) {
              const data = await DataService.getById(`${APIEndPoint}${clientId}/${pfId}`, accessToken);
              console.log("fetchModalData for id " + pfId);
              setModalData(data);
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchModalData();
  }, [clientId,pfId,isVisible]);

  const handleDelete = async () => {
    console.log("Proceeding to delete the portfolio " + pfId);
    try {
      await DataService.deleteById(`${APIEndPoint}${pfId}`, accessToken);
      console.log("Deletion completed for " + pfId);
      setIsVisible(false);
      console.log("Calling onFresh to refresh the table");
      onFresh();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    console.log("Delete Modal cancelled");
    setIsVisible(false);
  };

  return (
      modalData && isVisible ? (
        <DeleteModal 
                title='Delete Portfolio'
                message={`Are you sure you want to delete Portfolio ${modalData.portfolioName} ?`}
                handleDelete={handleDelete}
                handleClose={handleClose}
        />
      ) :  null
  );
};

export default withAuthenticationRequired(PortfolioDeleteModal, {
  onRedirecting: () => <Loading />,
});