import React, {useState,useContext, useEffect} from 'react';
import DataService from "../../DataService";
import NewUserModal from './NewUserModal'
import UserList from './UserList'
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import AppError from '../../../pages/AppError';
import { withAuthenticationRequired } from "@auth0/auth0-react";

const UsersPage = () => {

    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
    const { accessToken } = useAuthContext();

    const APIEndPoint = `${URL}/users/`;
    const [data, setData] = useState([]);
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

    const getLatestList = async () => {
        try {
            const response = await DataService.getAll(APIEndPoint, accessToken);
            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
            } else {
                setData(response.data);
                setState({
                    ...state,
                    showResult: true,
                    loading: false,
                    error: null,
                });
            }
        } catch (error) {
            console.log("User list Data fetch error : ", error.status);
            const AppErrorMessage = {
                        "status" : error.status,
                        "error" : error.response.data.error,
                        "message" : error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error : AppErrorMessage
            });
        }
    };

    useEffect(() => {
        getLatestList();
    }, []);

        return (
            <>
                {state.loading && (
                    <div> <Loading /> </div>
                )}
                {state.error && (
                    <div>
                        <AppError 
                            headerTitle={`App Error - ${state.error.status}`} 
                            messageTitle={state.error.error}
                            message={state.error.message}
                        />
                    </div>
                )}

                {state.showResult && (
                <div>
                <NewUserModal updateHandler={getLatestList}/>
                <UserList updateHandler={getLatestList}
                    data={data}/>
                </div>
                )}
            </>
        );
}

export default withAuthenticationRequired(UsersPage, {
    onRedirecting: () => <Loading />,
  });