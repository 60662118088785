import "./DeleteModal.css";

const DeleteModal = ({title, message, handleDelete, handleClose}) => {

return (
    <div className="delete-modal-container">
        <div>
          <h2>{title}</h2>
          <p>{message}</p>
          <button onClick={handleDelete}>Delete</button>
          &nbsp;&nbsp;
          <button onClick={handleClose}>Cancel</button>
        </div>
        </div>
)

};

export default DeleteModal;