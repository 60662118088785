import React, { useContext, useState } from 'react';
import WebForm from '../../html/WebForm';
import WebFormButton from '../../html/WebFormButton';

import NewFormElements from "./ProjectNewFormElements";
import { AppConfigContext } from '../../config/AppConfig';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const ProjectNewModal = ({ProgramId, updateHandler}) => {
  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
  const APIEndPoint = `${URL}/project/`;

  const [isFormVisible, setIsFormVisible] = useState(false);

  const preFormSubmission = (data) => {
        console.log("preFormSubmission");
        data.programId = `${ProgramId}`;
        console.log(data);
  };

  const postFormSubmission = (response) => {
        console.log("postFormSubmission");
        console.log(response);
        updateHandler();
  }

  const Form = {
        'title' : 'New Project',
        'restAPI' : APIEndPoint,
        'apiMethod' : 'POST',
        'elements' : NewFormElements,
        'preHandler' : preFormSubmission,
        'postHandler' : postFormSubmission
  };


  return (

    <div> {/* Changed container class */}
      <WebFormButton label={"+"} onClick={() => setIsFormVisible(true)}  />
      <br />
      <WebForm
        isVisible={isFormVisible}
        setIsVisible={setIsFormVisible}
        form={Form}
      /> 
    </div>
  );
};

export default withAuthenticationRequired(ProjectNewModal, {
  onRedirecting: () => <Loading />,
});